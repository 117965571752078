import React, { Component } from 'react'

function Work(props) {
  let {id} = props.match.params;
  return (
    <div>
      Work
      <p>{id}</p>
      <img src='/img/bg1.jpg'></img>
    </div>
  )
}

export default Work