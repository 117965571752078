import {Route, Link} from 'react-router-dom';
import Index from './components/Index';
import Work from './components/Work';

function App() {
  return (
    <>
      <Link to="/home">Index</Link>
      <Link to="/work">Work</Link>
      <Link to="/work/1">Work1</Link>

      {/* 路由表 */}
      <Route path="/home" exact component={Index} />
      <Route path="/work/:id" component={Work} />
    </>
  );
}

export default App;